import React from 'react';
import PropTypes from 'prop-types';
import styles from './BxHeaders.module.css';

const BxHeaders = () => (
  <div className="snippet" data-testid="BxHeaders">
    <div className="process-detail-description">Every times that you will call the API you will need to pass at header your integration's bluex credentials.</div>
    <table class="table table-atributes">
      <thead>
        <tr>
          <th scope="col"><strong>Header Param</strong></th>
          <th scope="col"><strong>Data Type</strong></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">
            <div><strong><em>BX-TOKEN</em></strong></div>
            <div>Authentication Bluex token.</div>
          </th>
          <td>String</td>
        </tr>
        <tr>
          <th scope="row">
            <div><strong><em>BX-USERCODE</em></strong></div>
            <div>Bluex user code.</div>
          </th>
          <td>Integer</td>
        </tr>
        <tr>
          <th scope="row">
            <div><strong><em>BX-CLIENT-ACCOUNT</em></strong></div>
            <div>Bluex cliente account.</div>
          </th>
          <td>String</td>
        </tr>
      </tbody>
    </table>
  </div>
);

BxHeaders.propTypes = {};

BxHeaders.defaultProps = {};

export default BxHeaders;
